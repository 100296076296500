import * as statusMapper from "@/service/error_request_mapper.js";

export class VacationReasonsService {
  constructor(api) {
    this.api = api;
  }

  async getAll() {
    return this.api
      .getAll()
      .then((data) => {
        data.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  async getById(reasonId) {
    return this.api.getById(reasonId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(reasonId);
      converter.convert(error);
    });
  }

  async create(reason) {
    return this.api.create(reason).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(reason.id);
      converter.add409AlreadyExists(reason.name);
      converter.convert(error);
    });
  }

  async update(reason) {
    return this.api.update(reason).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(reason.id);
      converter.convert(error);
    });
  }

  async delete(reasonId) {
    return this.api.delete(reasonId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(reasonId);
      converter.convert(error);
    });
  }
}
